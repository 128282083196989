import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Flex, Box, Heading } from "rebass"
import Divider from "../components/divider"
import { BookStore } from "../components"

const bookStores = [
  {
    country: "Australia",
    regions: [
      {
        name: "Queensland",
        stores: [
          {
            name: "MARY RYAN BOOKSTORE",
            address: "40 Park Road, MILTON QLD 4064",
            phone: "+61 73510 5000",
          },
          {
            name: "MARY RYAN BOOKSTORE",
            address:
              "Shop 7, Merthyr Village, 85 Merthyr Road, NEW FARM QLD 4005",
            phone: "+61 73254 0444",
          },
          {
            name: "AVID READER BOOKSHOP",
            address: "193 Boundary Street, WEST END, QLD, 4101",
            phone: "+61 73846 3422",
          },
          {
            name: "BOOKS @STONES & ALLMEDIC BOOK SUPPLIES",
            address: "360 Logan Road, STONES CORNERS",
            phone: "+61 73394 4949",
          },
          {
            name: "LITTLE GNOME BOOKSHOP",
            address: "66 Florence Street, WYNNUM, QLD, 4178",
            phone: "+61 73394 4949",
          },
          {
            name: "BERKELOUW EUMUNDI BOOKSHOP-CAFE",
            address: "EUMUNDI QLD 4562",
            phone: "+61 75442 8366",
          },
        ],
      },
      {
        name: "Victoria",
        stores: [
          {
            name: "T.S. BOOKSHOP",
            address: "126, Russell Street, MELBOURNE VIC 3000",
            phone: "+61 39650 3955",
            isStockingSoon: true,
          },
          {
            name: "DYMOCKS",
            address:
              "Lower Ground Floor, 234 Collins Street, MELBOURNE VIC 3000",
            phone: "+61 39663 0900",
            isStockingSoon: true,
          },
          {
            name: "RAINY DAY BOOKS",
            address: "1301 Mountain Highway, THE BASIN VIC 3154",
            phone: "+61 39662 9472",
          },
        ],
      },
      {
        name: "ACT",
        stores: [
          {
            name: "PAPERCHAIN BOOKSTORE",
            address: "34 Franklin Street, MANUKA ACT 2603",
            phone: "+61 26295 6723",
            isStockingSoon: true,
          },
        ],
      },
      {
        name: "Northern Territory",
        stores: [
          {
            name: "THE BOOKSHOP DARWIN (Pre-orders only)",
            address: "1/30 Smith St Mall, DARWIN NT 0800",
            phone: "+61 88941 3489",
          },
        ],
      },
    ],
  },
]

const AustralianBookStores = () => {
  return (
    <Layout>
      <SEO
        title={"Buy at these Australian Book Stores"}
        description={""}
        meta={[
          {
            name: "keywords",
            content:
              "Australian book stores, Brisbane, Melbourne, Darwin, Claude Tranchant, Boots to bliss, Boots to freedom",
          },
        ]}
      />
      <Flex flexWrap="wrap">
        <Box
          width={1}
          sx={{
            textAlign: ["center", "center", "left"],
          }}
        >
          <Heading fontSize={[5, 6]}>Australian Book Stores</Heading>
        </Box>
      </Flex>
      <Divider display={["none", "inherit"]} />
      <Flex flexWrap="wrap" mt={4}>
        {bookStores.map(({ regions }) => (
          <Box width={1}>
            {regions.map(({ name, stores }) => (
              <>
                <Box width={1}>
                  <Heading
                    fontSize={[3, 4]}
                    color={"light"}
                    sx={{ textDecoration: "underline" }}
                    fontStyle={"italic"}
                    mb={2}
                  >
                    {name}
                  </Heading>
                </Box>
                <Flex flexWrap={"wrap"}>
                  {stores.map(store => (
                    <Box width={[1, 1 / 2, 1 / 3]} pr={3}>
                      <BookStore p={2} width={[1, 1 / 2, 1 / 4]} {...store} />
                    </Box>
                  ))}
                </Flex>
              </>
            ))}
          </Box>
        ))}
      </Flex>
    </Layout>
  )
}

export default AustralianBookStores
