import React from "react"
import { Box } from "rebass"

const Divider = props => (
  <Box
    {...props}
    as="hr"
    sx={{
      bg: "extraLight",
      border: 0,
      height: 1,
      marginTop: 3,
      marginBottom: 3,
    }}
  />
)

export default Divider
